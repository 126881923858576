/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const dashboardApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_URL}/api`,
        prepareHeaders: async (headers) => {
            // check user token
            if (process.env.REACT_APP_BACKEND_APP) {
                headers.set('Authorization', `${process.env.REACT_APP_BACKEND_APP}`);
            }
            return headers;
        },
    }),
    reducerPath: 'dashboardApi',
    tagTypes: ['Dashboard'],
    endpoints: (builder) => ({
        getCustomizeCategory: builder.query({
            query: () => ({
                url: '/master/get-customize-options',
                method: 'GET',
            }),
        }),
        getTrendingDesignIcons: builder.query({
            query: () => ({
                url: '/seller/get-trending-icons',
                method: 'GET',
            }),
        }),
        getCommunityList: builder.query({
            query: () => ({
                url: '/seller/get-community-members',
                method: 'GET',
            }),
        }),
        getTestimonials: builder.query({
            query: () => ({
                url: '/seller/get-testimonials',
                method: 'GET',
            }),
        }),
        subscribeNewLatter: builder.mutation({
            query: (data) => ({
                url: '/seller/subscribe-now',
                method: 'POST',
                body: data
            })
        })
    })
});
export const {
    useGetCustomizeCategoryQuery,
    useGetTestimonialsQuery,
    useGetCommunityListQuery,
    useGetTrendingDesignIconsQuery
} = dashboardApi;
