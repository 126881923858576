import { lazy } from 'react';
import Pricing from '../pages/pricing/Pricing';

const Login = lazy(() => import('../pages/Auth/Login'));
const PrivacyPolicy = lazy(() => import('../pages/Polices/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('../pages/Polices/TermsAndConditions'));
const Registration = lazy(() => import('../pages/Auth/Registation'));
const Home = lazy(() => import('../pages/Home/Home'));
const MyProfile = lazy(() => import('../pages/MyProfile/MyProfile'));
const AddNewAddress = lazy(() => import('../pages/AddNewAddress/AddNewAddress'));
const NotFound = lazy(() => import('../pages/NotFound'));
const ProductGenerate = lazy(() => import('../pages/ProductGenerate/ProductGenerate'));
const ThemeCloth = lazy(() => import('../pages/ThemeCloth/ThemeCloth'));
// const ProductPrompt = lazy(() => import('../pages/ProductPrompt/ProductPrompt'));
const CustomizeClothing = lazy(() => import('../pages/CustomizeClothing/CustomizeClothing'));
const Cart = lazy(() => import('../pages/Cart/Cart'));
const Checkout = lazy(() => import('../pages/Checkout/Checkout'));
const Myorder = lazy(() => import('../pages/Myorder/Myorder'));
const Wishlist = lazy(() => import('../pages/Wishlist/Wishlist'));
const Images = lazy(() => import('../pages/FileUploadComponent/FileUploadComponent'));

export const routes = [
  {
    path: '/',
    element: Home,
    isPrivate: false,
    exact: true,
    viewLabel: true,
    isView: true,
    isLayout: true
  },
  {
    path: '/login',
    element: Login,
    isPrivate: false,
    exact: true,
    isView: true,
    isLayout: false
  },
  {
    path: '/pricing',
    element: Pricing,
    isPrivate: false,
    exact: true,
    isView: true,
    isLayout: true
  },
  {
    path: '/registration',
    element: Registration,
    isPrivate: false,
    exact: true,
    isView: true,
    isLayout: false
  },
  {
    path: 'my-profile',
    element: MyProfile,
    isPrivate: true,
    exact: true,
    viewLabel: true,
    isView: true,
    isLayout: true
  },
  {
    path: '/images',
    element: Images,
    isPrivate: true,
    exact: true,
    viewLabel: true,
    isView: true,
    isLayout: true
  },
  {
    path: 'address/:id?',
    element: AddNewAddress,
    isPrivate: true,
    exact: true,
    viewLabel: true,
    isView: true,
    isLayout: true
  },
  {
    path: '/product-generate',
    element: ProductGenerate,
    isPrivate: false,
    exact: true,
    viewLabel: true,
    isView: true,
    isLayout: true
  },
  {
    path: '/product-generate/theme',
    element: ProductGenerate,
    isPrivate: false,
    exact: true,
    viewLabel: true,
    isView: true,
    isLayout: true
  },
  {
    path: '/product-generate/customization',
    element: ProductGenerate,
    isPrivate: false,
    exact: true,
    viewLabel: true,
    isView: true,
    isLayout: true
  },
  // {
  //   path: '/search-design/:sub_sub_category',
  //   element: ProductPrompt,
  //   isPrivate: false,
  //   exact: true,
  //   viewLabel: true,
  //   isView: true,
  //   isLayout: true
  // },
  // {
  //   path: '/search-design',
  //   element: ProductPrompt,
  //   isPrivate: false,
  //   exact: true,
  //   viewLabel: true,
  //   isView: true,
  //   isLayout: true
  // },
  {
    path: '/theme-cloth/:sub_sub_category',
    element: ThemeCloth,
    isPrivate: false,
    exact: true,
    viewLabel: true,
    isView: true,
    isLayout: true
  },
  {
    path: '/customize-clothing/:sub_sub_category',
    element: CustomizeClothing,
    isPrivate: false,
    exact: true,
    viewLabel: true,
    isView: true,
    isLayout: true
  },
  {
    path: '/wish-list',
    element: Wishlist,
    isPrivate: false,
    exact: true,
    viewLabel: true,
    isView: true,
    isLayout: true
  },
  {
    path: '/cart',
    element: Cart,
    isPrivate: false,
    exact: true,
    viewLabel: true,
    isView: true,
    isLayout: true
  },
  {
    path: '/checkout',
    element: Checkout,
    isPrivate: false,
    exact: true,
    viewLabel: true,
    isView: true,
    isLayout: true
  },
  {
    path: '/my-order',
    element: Myorder,
    isPrivate: false,
    exact: true,
    viewLabel: true,
    isView: true,
    isLayout: true
  },
  {
    path: 'privacy-policy',
    element: PrivacyPolicy,
    isPrivate: false,
    exact: true,
    viewLabel: true,
    isView: true,
    isLayout: false
  },
  {
    path: 'terms-and-condition',
    element: TermsAndConditions,
    isPrivate: false,
    exact: true,
    viewLabel: true,
    isView: true,
    isLayout: false
  },
  {
    path: '/my-order',
    element: Myorder,
    isPrivate: false,
    exact: true,
    viewLabel: true,
    isView: true,
    isLayout: true
  },
  {
    path: '*',
    element: NotFound,
    isPrivate: false,
    exact: false,
    viewLabel: true,
    isLayout: false
  }
];
