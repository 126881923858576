// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add_new_address_wrap .new_address_info .new_address_title h4 {
  font-size: 17px;
  line-height: 1.2;
  font-weight: 500;
}
.add_new_address_wrap .new_address_info .new_address_title {
  margin-bottom: 20px;
}
.add_new_address_wrap .new_address_info .new_address_filed {
  padding-left: 20px;
}

.common_radio_btn .ant-form-item-label label {
  opacity: 0;
}
.common_radio_btn .ant-form-item-control-input {
  height: 50px;
  display: flex;
  align-items: center;
}
.common_radio_btn .ant-form-item-control-input .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #984CFA;
  background-color: #984CFA;
}
.common_radio_btn .ant-form-item-control-input .ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #984CFA;
}

@media screen and (max-width: 767px) {
  .add_new_address_wrap .new_address_info .new_address_filed {
    padding-left: 0;
  }
  .common_radio_btn .ant-form-item-label label {
    display: none;
  }
  .common_radio_btn .ant-radio-wrapper {
    margin-bottom: 8px;
  }
  .new_address_inner .common_input {
    margin-bottom: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/AddNewAddress/AddNewAddress.scss"],"names":[],"mappings":"AAIY;EACI,eAAA;EACA,gBAAA;EACA,gBAAA;AAHhB;AAOQ;EACI,mBAAA;AALZ;AAQQ;EACI,kBAAA;AANZ;;AAaQ;EACI,UAAA;AAVZ;AAaI;EACI,YAAA;EACA,aAAA;EACA,mBAAA;AAXR;AAaQ;EACI,qBAAA;EACA,yBAAA;AAXZ;AAcQ;EACI,qBAAA;AAZZ;;AAiBA;EAGY;IACI,eAAA;EAhBd;EAsBU;IACI,aAAA;EApBd;EAwBM;IACI,kBAAA;EAtBV;EA2BM;IACI,mBAAA;EAzBV;AACF","sourcesContent":[".add_new_address_wrap {\n\n    .new_address_info {\n        .new_address_title {\n            h4 {\n                font-size: 17px;\n                line-height: 1.2;\n                font-weight: 500;\n            }\n        }\n\n        .new_address_title {\n            margin-bottom: 20px;\n        }\n\n        .new_address_filed {\n            padding-left: 20px;\n        }\n    }\n}\n\n.common_radio_btn {\n    .ant-form-item-label{\n        label{\n            opacity: 0;\n        }\n    }\n    .ant-form-item-control-input {\n        height: 50px;\n        display: flex;\n        align-items: center;\n\n        .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {\n            border-color: #984CFA;\n            background-color: #984CFA;\n        }\n        \n        .ant-radio-wrapper:hover .ant-radio-inner{\n            border-color: #984CFA;\n        }\n    }\n}\n\n@media screen and (max-width: 767px) {\n    .add_new_address_wrap{\n        .new_address_info{\n            .new_address_filed {\n                padding-left: 0;\n            }\n        }\n    }\n    .common_radio_btn {\n        .ant-form-item-label{\n            label{\n                display: none;\n            }\n        }\n\n        .ant-radio-wrapper{\n            margin-bottom: 8px;\n        }\n    }\n\n    .new_address_inner{\n        .common_input{\n            margin-bottom: 15px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
