import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    errorMsg: '',
    loading: false,
    iconLoading: false,
    iconsList: {}
};

const wishListSlice = createSlice({
    name: 'WISH_LIST_SLICE',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.errorMsg = action.payload;
        },
        setIconLoading: (state, action) => {
            state.iconLoading = action.payload;
        },
        setIconList: (state, action) => {
            state.iconsList = action.payload;
        }
    }
});

export const {
    setLoading,
    setIconLoading,
    setError,
    setIconList
} = wishListSlice.actions;
export default wishListSlice.reducer;
