import React from 'react';
import Layout from '../components/Layout';
import { useAuth } from '../hooks/useAuth';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const validUser = useAuth();
  return validUser ? <Layout>{children}</Layout> : <Navigate to="/login" />;
};

export default PrivateRoute;
