
import './Pricing.scss';
// import PricingCard from '../../components/common/pricing-cards/PricingCard';
import PricingCardList from '../../components/common/pricing-cards/PricingCardList';
const Pricing = () => {
  // const [mtoggled, msetToggled] = useState(true);
  // const [ytoggled, ysetToggled] = useState(false);

  // const handleMToggle = () => {
  //   msetToggled(!mtoggled);
  //   ysetToggled(!ytoggled);
  // };
  // const handleYToggle = () => {
  //   ysetToggled(!ytoggled);
  //   msetToggled(!mtoggled);
  // };
  return (
    <div className="pricing_page_banner">
      <div className="container pricing_page_banner">
        <div className="title-grp">
          <h1 className="title-pricing">Pricing</h1>
          <div className="underline"></div>
        </div>
        <div className="pricing-description">
          <p className="p-text">
            Give Nivera a try and see the power of AI generated design for yourself. Easily upgrade
            to experience more features or get in touch to explore the Business Plus package for
            limitless creative freedom.
          </p>
        </div>
        <div className="toggle-pricing">
          {/* <div
            // className={`monthly-pricing t-btn ${mtoggled ? 'active' : 'not-active'}`}
            className={`monthly-pricing t-btn`}
            // onClick={handleMToggle}
            >
            Monthly
          </div> */}
          {/* <div
            className={`monthly-pricing t-btn ${ytoggled ? 'active' : 'not-active'}`}
            onClick={handleYToggle}>
            Annually
          </div> */}
        </div>
        <PricingCardList monthly={true} annually={false} />

      </div>
    </div>
  );
};
export default Pricing;
