import { combineReducers } from 'redux';
import users from './users';
import dashboard from './home';
import customization from './productCustomization';
import wishList from './wishList';
import { userApi } from './users/services';
import { dashboardApi } from './home/services';
import { productCustomizationApi } from './productCustomization/services';
import { wishListApi } from './wishList/services';

export default combineReducers({
  users,
  dashboard,
  customization,
  wishList,
  [userApi.reducerPath]: userApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [productCustomizationApi.reducerPath]: productCustomizationApi.reducer,
  [wishListApi.reducerPath]: wishListApi.reducer
});
