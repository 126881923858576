import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

// Return value should be component

const Loader = () => (
  <div className="main-spinner">
    <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
  </div>
);

export default Loader;
