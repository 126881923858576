import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { useSelector } from 'react-redux';
import { routes } from './routes';
import Loader from '../components/common/Loader';
import NotFound from '../pages/NotFound';
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../style/global.scss';
import Layout from '../components/Layout';
import { useAuth } from '../hooks/useAuth';
import ReactGA from 'react-ga4';

const validateRoutes = ['/login', '/registration'];

const RouterList = () => {
  const { loader } = useSelector((state) => state);
  const navigate = useNavigate();
  const isAuth = useAuth();

  // if user not login complete flow so, isNewUser in storage always true
  useEffect(() => {
    if (isAuth && validateRoutes.includes(window?.location?.pathname)) navigate('/');
  }, []);

  useEffect(() => {
    if (loader) {
      const element = document.getElementsByTagName('body')[0];
      element.classList.add('loader');
    } else {
      const element = document.getElementsByTagName('body')[0];
      element.classList.remove('loader');
    }
  }, [loader]);

  return (
    <div className="RouterList">
      {loader && <Loader />}
      {/* <BrowserRouter> */}
      <Suspense fallback={<div />}>
        <Routes>
          {routes &&
            routes.map((route, index) => {
              return route.isView ? (
                <Route
                  path={route.path}
                  key={index}
                  exact={route.exact}
                  render={props=>{ReactGA.send(props.location.pathname);}}
                  element={
                    <>
                      {route.isPrivate ? (
                        <PrivateRoute isLayout={route.isLayout} isView={route.isView}>
                          <route.element />
                        </PrivateRoute>
                      ) : route.isLayout ? (
                        <Layout>
                          <route.element />
                        </Layout>
                      ) : (
                        <route.element />
                      )}
                    </>
                  }
                />
              ) : (
                <Route path={route.path} key={index} exact={true} element={<NotFound />} />
              );
            })}
        </Routes>
      </Suspense>
      {/* </BrowserRouter> */}
    </div>
  );
};
export default RouterList;
