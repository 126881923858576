// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product_information {
  display: flex;
  align-items: center;
}
.product_information .product_img {
  width: 60px;
  height: 65px;
  padding: 7px;
  background-color: #F6F6F6;
  border-radius: 7px;
  margin-right: 30px;
}
.product_information .product_img img {
  max-width: 100%;
}

.download_btn_wrap {
  background-color: #984CFA;
  height: 36px;
  width: 136px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.download_btn_wrap:hover {
  background-color: #000 !important;
}
.download_btn_wrap svg {
  min-width: 16px;
}
.download_btn_wrap span {
  font-size: 17px;
  line-height: 1.2;
  color: #F6F6F6;
  display: flex;
  margin-left: 6px;
}

.status_wrap {
  display: flex;
  align-items: center;
}
.status_wrap span {
  line-height: 1;
  font-weight: 700;
  display: flex;
  margin-left: 8px;
  margin-top: 2px;
}

.green_text {
  color: #51B124;
}

.orange_text {
  color: #FF8227;
}

.red_text {
  color: #FF4A4A;
}

.common_table_design table tbody td {
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/pages/Myorder/Myorder.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;AACR;AACQ;EACI,eAAA;AACZ;;AAIA;EACI,yBAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AADJ;AAGI;EACI,iCAAA;AADR;AAII;EACI,eAAA;AAFR;AAKI;EACI,eAAA;EACA,gBAAA;EACA,cAAA;EACA,aAAA;EACA,gBAAA;AAHR;;AAOA;EACI,aAAA;EACA,mBAAA;AAJJ;AAMI;EACI,cAAA;EACA,gBAAA;EACA,aAAA;EACA,gBAAA;EACA,eAAA;AAJR;;AAQA;EACI,cAAA;AALJ;;AAQA;EACI,cAAA;AALJ;;AAQA;EACI,cAAA;AALJ;;AAWY;EACI,gBAAA;AARhB","sourcesContent":[".product_information {\n    display: flex;\n    align-items: center;\n\n    .product_img {\n        width: 60px;\n        height: 65px;\n        padding: 7px;\n        background-color: #F6F6F6;\n        border-radius: 7px;\n        margin-right: 30px;\n\n        img {\n            max-width: 100%;\n        }\n    }\n}\n\n.download_btn_wrap {\n    background-color: #984CFA;\n    height: 36px;\n    width: 136px;\n    padding: 5px 10px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 5px;\n\n    &:hover{\n        background-color: #000 !important;\n    }\n\n    svg {\n        min-width: 16px;\n    }\n\n    span {\n        font-size: 17px;\n        line-height: 1.2;\n        color: #F6F6F6;\n        display: flex;\n        margin-left: 6px;\n    }\n}\n\n.status_wrap {\n    display: flex;\n    align-items: center;\n\n    span {\n        line-height: 1;\n        font-weight: 700;\n        display: flex;\n        margin-left: 8px;\n        margin-top: 2px;\n    }\n}\n\n.green_text {\n    color: #51B124;\n}\n\n.orange_text {\n    color: #FF8227;\n}\n\n.red_text {\n    color: #FF4A4A;\n}\n\n.common_table_design{\n    table{\n        tbody{\n            td{\n                font-weight: 600;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
