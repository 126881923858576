import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    errorMsg: '',
    loading: false,
    iconLoading: false,
    testimonialLoading: false,
    communityLoading: false,
    customizeCategory: [],
    trendingIcons: [],
    testimonials: [],
    communities: [],
    subscribe: {}
};

const dashboardSlice = createSlice({
    name: 'DASHBOARD_SLICE',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setIconLoading: (state, action) => {
            state.iconLoading = action.payload;
        },
        setTestimonialLoading: (state, action) => {
            state.testimonialLoading = action.payload;
        },
        setCommunityLoading: (state, action) => {
            state.communityLoading = action.payload;
        },
        setError: (state, action) => {
            state.errorMsg = action.payload;
        },
        setCustomizeCategory: (state, action) => {
            state.customizeCategory = action.payload;
        },
        setTrendingIcons: (state, action) => {
            state.trendingIcons = action.payload;
        },
        setTestimonials: (state, action) => {
            state.testimonials = action.payload;
        },
        setCommunities: (state, action) => {
            state.communities = action.payload;
        },
        setSubscribe: (state, action) => {
            state.subscribe = action.payload;
        }
    }
});

export const {
    setLoading,
    setIconLoading,
    setError,
    setCustomizeCategory,
    setTrendingIcons,
    setTestimonials,
    setTestimonialLoading,
    setCommunities,
    setCommunityLoading,
    setSubscribe
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
