/* eslint-disable no-undef */
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import { userApi } from './users/services';
import rootReducer from './rootReducer';
import { dashboardApi } from './home/services';
import { productCustomizationApi } from './productCustomization/services';
import { wishListApi } from './wishList/services';
import { imageApi } from './image/services';

export const makeStore = () => {
  return configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false
      }).concat(
        userApi.middleware,
        dashboardApi.middleware,
        productCustomizationApi.middleware,
        wishListApi.middleware,
        imageApi.middleware
      )
  });
};

const store = makeStore();

// optional, but required for refetchOnFocus/refetchOnReconnect/refetchOnMountOrArgChange  etc behaviors
setupListeners(store.dispatch);

export default store;
