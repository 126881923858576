import PricingCard from './PricingCard';

const PricingCardList = ({ annually, monthly }) => {
  console.log(annually, monthly);
  return (
    <>
      {annually && <PricingCard annually={annually} monthly={monthly} />}
      {monthly && <PricingCard annually={annually} monthly={monthly} />}
    </>
  );
};
export default PricingCardList;
