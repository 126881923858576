// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-spinner {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  z-index: 9999;
}
.main-spinner .ant-spin {
  color: #2711FF !important;
}

.loader::before {
  content: " ";
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  left: 0;
  top: 0;
  z-index: 9999;
  opacity: 0.2;
}`, "",{"version":3,"sources":["webpack://./src/style/Loader.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,qBAAA;EACA,aAAA;AACF;AACE;EACE,yBAAA;AACJ;;AAGA;EACE,YAAA;EACA,eAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,OAAA;EACA,MAAA;EACA,aAAA;EACA,YAAA;AAAF","sourcesContent":[".main-spinner {\n  position: fixed !important;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  display: inline-block;\n  z-index: 9999;\n\n  .ant-spin {\n    color: #2711FF !important;\n  }\n}\n\n.loader::before {\n  content: \" \";\n  position: fixed;\n  height: 100vh;\n  width: 100vw;\n  background-color: #000;\n  left: 0;\n  top: 0;\n  z-index: 9999;\n  opacity: 0.2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
