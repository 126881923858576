import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errorMsg: '',
  loading: false,
  aiDesignLoading: false,
  categories: [],
  subCategories: [],
  subSubCategories: [],
  themes: [],
  allDesignIcon: [],
  aiDesign: [],
  meta: {},
  icon: [],
  productList: [],
  productDetails: {}
};

const productCustomizationSlice = createSlice({
  name: 'PRODUCT_CUSTOMIZATION_SLICE',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setError: (state, action) => {
      state.errorMsg = action.payload;
    },
    setSubCategories: (state, action) => {
      state.subCategories = action.payload;
    },
    setSubSubCategories: (state, action) => {
      state.subSubCategories = action.payload;
    },
    setThemes: (state, action) => {
      state.themes = action.payload;
    },
    setAllDesignIcon: (state, action) => {
      state.allDesignIcon = action.payload;
    },
    setMetaDesignIcon: (state, action) => {
      state.meta = action.payload;
    },
    setAiDesignLoader: (state, action) => {
      state.aiDesignLoading = action.payload;
    },
    setAiDesign: (state, action) => {
      state.aiDesign = action.payload;
    },
    setIcon: (state, action) => {
      state.icon = action.payload;
    },
    setProductList: (state, action) => {
      state.productList = action.payload;
    },
    setProductDetails: (state, action) => {
      state.productDetails = action.payload;
    }
  }
});

export const {
  setLoading,
  setCategories,
  setError,
  setSubCategories,
  setSubSubCategories,
  setThemes,
  setAllDesignIcon,
  setMetaDesignIcon,
  setAiDesignLoader,
  setAiDesign,
  setIcon,
  setProductList,
  setProductDetails
} = productCustomizationSlice.actions;
export default productCustomizationSlice.reducer;
