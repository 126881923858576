import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import profile_icon from '../../assets/images/home/profile_icon.png';
import { Button } from 'antd';
import useWindowSize from '../../hooks/useWindowsSize';
import { Dropdown, Space } from 'antd';
import { useAuth } from '../../hooks/useAuth';
import { useAppSelector } from '../../redux/hooks';
import { HeartOutlined } from '@ant-design/icons';


// const calendlyLink = 'https://calendly.com/nivera-ai/nivera-ai-virtual-try-on-demo';
// const items = [
//   {
//     label: (
//       <Link rel="noopener noreferrer" to="/product-generate">
//         AI-based clothing generation
//       </Link>
//     ),
//     key: '0'
//   },
//   {
//     label: (
//       <Link rel="noopener noreferrer" to="/product-generate/theme">
//         Theme-based clothing
//       </Link>
//     ),
//     key: '1'
//   },
//   {
//     label: (
//       <Link rel="noopener noreferrer" to="/product-generate/customization">
//         Customize Clothing
//       </Link>
//     ),
//     key: '2'
//   }
// ];

const profileItems = [
  {
    key: '0',
    label: (
      <Link rel="noopener noreferrer" to="/my-profile">
        <p>
          <svg
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.5 18H12V14.2178C11.9994 13.6297 11.7656 13.066 11.3498 12.6502C10.934 12.2344 10.3703 12.0006 9.78225 12H3.71775C3.12975 12.0006 2.566 12.2344 2.15022 12.6502C1.73444 13.066 1.5006 13.6297 1.5 14.2178V18H0V14.2178C0.00119087 13.2321 0.393264 12.2872 1.09022 11.5902C1.78717 10.8933 2.73211 10.5012 3.71775 10.5H9.78225C10.7679 10.5012 11.7128 10.8933 12.4098 11.5902C13.1067 12.2872 13.4988 13.2321 13.5 14.2178V18Z"
              fill="#28252B"
            />
            <path
              d="M6.75 9C5.85999 9 4.98996 8.73608 4.24994 8.24162C3.50991 7.74715 2.93314 7.04434 2.59254 6.22208C2.25195 5.39981 2.16283 4.49501 2.33647 3.6221C2.5101 2.74918 2.93869 1.94736 3.56802 1.31802C4.19736 0.688685 4.99918 0.260102 5.8721 0.0864683C6.74501 -0.0871652 7.64981 0.00194979 8.47208 0.342544C9.29434 0.683138 9.99715 1.25991 10.4916 1.99994C10.9861 2.73996 11.25 3.60999 11.25 4.5C11.2488 5.69311 10.7743 6.83701 9.93067 7.68067C9.08701 8.52432 7.94311 8.99881 6.75 9ZM6.75 1.5C6.15666 1.5 5.57664 1.67595 5.08329 2.00559C4.58994 2.33524 4.20543 2.80377 3.97836 3.35195C3.7513 3.90013 3.69189 4.50333 3.80765 5.08527C3.9234 5.66722 4.20912 6.20177 4.62868 6.62132C5.04824 7.04088 5.58279 7.3266 6.16473 7.44236C6.74667 7.55811 7.34987 7.4987 7.89805 7.27164C8.44623 7.04458 8.91477 6.66006 9.24441 6.16671C9.57406 5.67337 9.75 5.09335 9.75 4.5C9.75 3.70435 9.43393 2.94129 8.87132 2.37868C8.30871 1.81607 7.54565 1.5 6.75 1.5Z"
              fill="#28252B"
            />
          </svg>
        </p>
        <span>My Profile</span>
      </Link>
    )
  },
  {
    key: '1',
    label: (
      <Link rel="noopener noreferrer" to="/wish-list">
        <p>
          <svg
            width="19"
            height="17"
            viewBox="0 0 19 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.00055 15.9012C9.13895 16.0339 9.32001 16.1 9.5 16.1C9.67999 16.1 9.86105 16.0339 9.99945 15.9012L16.5363 9.63269C16.5363 9.63269 16.5363 9.63268 16.5363 9.63267C17.5438 8.66717 18.1 7.38141 18.1 6.01366C18.1 4.64588 17.5437 3.36071 16.5363 2.39465C14.6171 0.554192 11.5882 0.413831 9.49999 1.97143C7.41182 0.414414 4.38354 0.554784 2.46371 2.39463L2.46371 2.39463C1.45625 3.36013 0.9 4.6459 0.9 6.01366C0.9 7.38144 1.45627 8.66661 2.46368 9.63267L9.00055 15.9012ZM2.31707 6.01308C2.31707 5.01281 2.7235 4.0723 3.46258 3.36356C4.22532 2.63271 5.22782 2.2663 6.23156 2.2663C7.23536 2.2663 8.23784 2.63216 9.00055 3.36356C9.27717 3.62883 9.72283 3.62883 9.99945 3.36356C11.5259 1.90035 14.011 1.90035 15.5374 3.36356C16.2765 4.0723 16.6829 5.01281 16.6829 6.01308C16.6829 7.01335 16.2765 7.95385 15.5374 8.66259L9.5 14.4522L3.46258 8.66259C2.7235 7.95385 2.31707 7.01335 2.31707 6.01308Z"
              fill="#28252B"
              stroke="#444146"
              strokeWidth="0.2"
            />
          </svg>
        </p>
        <span>Wishlist</span>
      </Link>
    )
  },
  // {
  //   key: '2',
  //   label: (
  //     <Link rel="noopener noreferrer" to="https://www.antgroup.com">
  //       <p>
  //         <svg
  //           width="16"
  //           height="16"
  //           viewBox="0 0 16 16"
  //           fill="none"
  //           xmlns="http://www.w3.org/2000/svg">
  //           <path
  //             d="M16 2H2.828L2.8 1.766C2.7427 1.27961 2.50892 0.831155 2.14299 0.505652C1.77706 0.180149 1.30442 0.000227862 0.814667 0L0 0V1.33333H0.814667C0.977956 1.33336 1.13556 1.3933 1.25758 1.50181C1.3796 1.61032 1.45756 1.75983 1.47667 1.922L2.53333 10.9007C2.59063 11.3871 2.82441 11.8355 3.19034 12.161C3.55627 12.4865 4.02891 12.6664 4.51867 12.6667H13.3333V11.3333H4.51867C4.35528 11.3333 4.19759 11.2733 4.07555 11.1646C3.95351 11.056 3.87562 10.9063 3.85667 10.744L3.76933 10H14.5573L16 2ZM13.4427 8.66667H3.61267L2.98533 3.33333H14.4047L13.4427 8.66667Z"
  //             fill="#28252B"
  //           />
  //           <path
  //             d="M4.66536 15.9997C5.40174 15.9997 5.9987 15.4027 5.9987 14.6663C5.9987 13.93 5.40174 13.333 4.66536 13.333C3.92898 13.333 3.33203 13.93 3.33203 14.6663C3.33203 15.4027 3.92898 15.9997 4.66536 15.9997Z"
  //             fill="#28252B"
  //           />
  //           <path
  //             d="M11.3333 15.9997C12.0697 15.9997 12.6667 15.4027 12.6667 14.6663C12.6667 13.93 12.0697 13.333 11.3333 13.333C10.597 13.333 10 13.93 10 14.6663C10 15.4027 10.597 15.9997 11.3333 15.9997Z"
  //             fill="#28252B"
  //           />
  //         </svg>
  //       </p>
  //       <span>My Orders</span>
  //     </Link>
  //   )
  // },
  {
    key: '3',
    label: (
      <Link rel="noopener noreferrer" to="/login" onClick={() => localStorage.clear()}>
        <p>
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.5716 6.95638L10.6797 4.06445L9.85418 4.89289L12.3664 7.40507H4.13867V8.57353H12.3664L9.85418 11.0857L10.6803 11.9118L13.5722 9.01988C13.7079 8.88425 13.8155 8.72322 13.8889 8.54599C13.9623 8.36876 14.0001 8.1788 14.0001 7.98696C14.0001 7.79513 13.9623 7.60517 13.8889 7.42794C13.8155 7.25071 13.7079 7.08968 13.5722 6.95405L13.5716 6.95638Z"
              fill="#28252B"
            />
            <path
              d="M6.39284 13.2473C6.39284 13.4023 6.33129 13.5509 6.22172 13.6604C6.11216 13.77 5.96356 13.8315 5.80861 13.8315H2.02515C1.8702 13.8315 1.7216 13.77 1.61203 13.6604C1.50247 13.5509 1.44092 13.4023 1.44092 13.2473V2.7312C1.44092 2.57625 1.50247 2.42765 1.61203 2.31809C1.7216 2.20853 1.8702 2.14697 2.02515 2.14697H5.80861C5.96356 2.14697 6.11216 2.20853 6.22172 2.31809C6.33129 2.42765 6.39284 2.57625 6.39284 2.7312V5.84689H7.5613V2.7312C7.5613 2.26636 7.37664 1.82056 7.04795 1.49187C6.71925 1.16317 6.27345 0.978516 5.80861 0.978516L2.02515 0.978516C1.56031 0.978516 1.1145 1.16317 0.785811 1.49187C0.457118 1.82056 0.272461 2.26636 0.272461 2.7312L0.272461 13.2473C0.272461 13.7122 0.457118 14.158 0.785811 14.4867C1.1145 14.8153 1.56031 15 2.02515 15H5.80861C6.27345 15 6.71925 14.8153 7.04795 14.4867C7.37664 14.158 7.5613 13.7122 7.5613 13.2473V10.1316H6.39284V13.2473Z"
              fill="#28252B"
            />
          </svg>
        </p>
        <span>Sign Out</span>
      </Link>
    )
  }
];

const Header = () => {
  const { user } = useAppSelector((state) => state?.users);
  const size = useWindowSize();
  const isAuth = useAuth();

  // const redirectToCalendly = () => {
  //   window.open(calendlyLink); 
  // }
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className={toggleMenu ? 'main_header mobile-open' : 'main_header'}>
      <div className="container">
        <div className="header_inner">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
          <div className="menu_items">
            <nav>
              <ul className="menu_list">
                <li>
                  <Link to="/images">
                    Virtual Try On
                  </Link>
                </li>
                <li>
                  <NavLink
                    to="/pricing"
                    className={`${({ isActive }) => (isActive ? 'active' : '')} pricing`}>
                    Pricing
                  </NavLink>
                </li>
                {/* <li>
                  <Dropdown
                    menu={{
                      items
                    }}
                    // trigger={'click'}
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      <Space>
                        Customize
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M4.16656 5.00591C4.25734 5.10101 4.37817 5.15 4.50001 5.15C4.62186 5.15 4.74269 5.10101 4.83347 5.00591L8.01525 1.67262C8.19501 1.48442 8.1948 1.18229 8.01532 0.99415C7.83195 0.80193 7.53177 0.801981 7.34837 0.994115L4.50001 3.9781L1.65169 0.99415C1.65168 0.994139 1.65167 0.994127 1.65166 0.994115C1.46824 0.801884 1.16812 0.802134 0.984777 0.994081C0.805057 1.18224 0.805103 1.48439 0.984744 1.67259L4.16656 5.00591Z"
                            fill="#28252B"
                            stroke="#28252B"
                            strokeWidth="0.3"
                          />
                        </svg>
                      </Space>
                    </Link>
                  </Dropdown>
                </li> */}
                {/* <li>
                  <Link to="/">About Us</Link>
                </li>

                <li>
                  <Link to="/search-design"> Search Designs</Link>
                </li> */}
              </ul>
              <div className="mobile-open-btn menu-toggle-btn" onClick={() => setToggleMenu(false)}>
                <span />
                <span />
                <span />
              </div>
            </nav>
          </div>
          <div className="right_login_info">
            <div className="common_header_btn">
              <Button type="primary" shape="circle" icon={<HeartOutlined />} />
            </div>

            {/* <div className="cart_btn common_header_btn">
              <Button type="dash">
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.5926 8.11115C19.1334 7.57939 18.4646 7.28128 17.7637 7.28128H17.6912C17.2883 3.6637 14.2105 0.835693 10.4882 0.835693C6.76589 0.835693 3.68006 3.6637 3.27722 7.28128H3.23693C2.53597 7.28128 1.86724 7.58745 1.40799 8.11115C0.948746 8.64292 0.747321 9.34387 0.844005 10.0368L1.69805 16.0151C2.03644 18.3838 4.09097 20.1644 6.4839 20.1644H14.5167C16.9096 20.1644 18.9642 18.3838 19.3026 16.0151L20.1566 10.0368C20.2533 9.34387 20.0519 8.64292 19.5926 8.11115ZM10.4882 2.44709C13.3243 2.44709 15.6769 4.54997 16.0717 7.28128H4.90473C5.29952 4.54997 7.65216 2.44709 10.4882 2.44709ZM18.5613 9.81118L17.7073 15.7895C17.4817 17.3686 16.112 18.553 14.5167 18.553H6.4839C4.88861 18.553 3.51893 17.3606 3.29333 15.7895L2.43929 9.81118C2.40706 9.57753 2.47152 9.35193 2.6246 9.16662C2.77768 8.98937 2.99522 8.88462 3.23693 8.88462H17.7637C17.9973 8.88462 18.2149 8.98131 18.368 9.15856C18.521 9.33582 18.5935 9.56947 18.5533 9.80312L18.5613 9.81118Z"
                    fill="#28252B"
                  />
                </svg>
                <span>2</span>
              </Button>
            </div> */}
            {isAuth ? (
              <div className="profile_design_wrap">
                <Dropdown
                  menu={{
                    items: profileItems
                  }}>
                  <Link onClick={(e) => e.preventDefault()}>
                    <Space>
                      <div className="profile_icon_inner">
                        <p className="profile_icon">
                          <img
                            src={
                              user?.customer_image_url ||
                              localStorage?.getItem('profile_pic') ||
                              profile_icon
                            }
                            alt="profile men"
                          />
                        </p>
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M4.16656 5.00591C4.25734 5.10101 4.37817 5.15 4.50001 5.15C4.62186 5.15 4.74269 5.10101 4.83347 5.00591L8.01525 1.67262C8.19501 1.48442 8.1948 1.18229 8.01532 0.99415C7.83195 0.80193 7.53177 0.801981 7.34837 0.994115L4.50001 3.9781L1.65169 0.99415C1.65168 0.994139 1.65167 0.994127 1.65166 0.994115C1.46824 0.801884 1.16812 0.802134 0.984777 0.994081C0.805057 1.18224 0.805103 1.48439 0.984744 1.67259L4.16656 5.00591Z"
                            fill="#fff"
                            stroke="#fff"
                            strokeWidth="0.3"
                          />
                        </svg>
                      </div>
                    </Space>
                  </Link>
                </Dropdown>
                {/* <Badge count={5}>
                  <div className="notification_icon">
                    <NotificationOutlined
                      style={{
                        fontSize: '16px',
                        color: '#fff'
                      }}
                    />
                  </div>
                </Badge> */}
              </div>
            ) : (
              <div className="login_wrap">
                <Link to="/login" className="login_btn">
                  <span>Login</span>
                </Link>
              </div>
            )}
          </div>
          {size?.width <= 991 ? (
            <div className="menu-toggle-btn" onClick={() => setToggleMenu(true)}>
              <span />
              <span />
              <span />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Header;
