import { notification } from 'antd';
import RouterList from './route';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';

function App() {
  const TRACKING_ID = "G-Y5N0MCSP55";
  ReactGA.initialize(TRACKING_ID);
  notification.config({
    top: 10,
    right: 1,
    duration: 3,
    maxCount: 3,
    rtl: false,
    // prefixCls: 'custom-message',
  });
  return (
    <div className="App">
      <BrowserRouter>
        <RouterList />
      </BrowserRouter>
    </div>
  );
}

export default App;
