/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const productCustomizationApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/api`,
    prepareHeaders: async (headers) => {
      // check user token
      if (process.env.REACT_APP_BACKEND_APP) {
        headers.set('Authorization', `${process.env.REACT_APP_BACKEND_APP}`);
      }
      return headers;
    }
  }),
  reducerPath: 'productCustomizationApi',
  tagTypes: ['productCustomization'],
  endpoints: (builder) => ({
    getCategories: builder.mutation({
      query: () => ({
        url: '/master/get-categories',
        method: 'GET' 
      })
    }),
    getSubCategories: builder.mutation({
      query: (category_id) => ({
        url: `/master/get-sub-categories?category_id=${category_id}`,
        method: 'GET'
      })
    }),
    getSubSubCategories: builder.mutation({
      query: ({ categoryId, subCategoryId }) => ({
        url: categoryId
          ? `/master/get-sub-sub-categories?category_id=${categoryId}&sub_category_id=${subCategoryId}`
          : `/master/get-sub-sub-categories?sub_category_id=${subCategoryId}`,
        method: 'GET'
      })
    }),
    getThemes: builder.mutation({
      query: ({ themeSearchValue }) => ({
        url: !themeSearchValue
          ? 'seller/get-themes'
          : `seller/get-themes?is_active=True&search=${themeSearchValue ?? ''}`,
        method: 'GET'
      })
    }),
    getAllDesignIcon: builder.mutation({
      query: (data) => ({
        url: data.themeId
          ? `/seller/get-design-icons?is_active=True&is_public=True&page=${
              data.page
            }&pageSize=2&theme_id=${data.themeId}&search=${data.designSearchValue ?? ''}`
          : `/seller/get-design-icons?is_active=True&is_public=True&page=${
              data.page
            }&pageSize=2&search=${data.designSearchValue ?? ''}`,
        method: 'GET'
      })
    }),
    getAiDesign: builder.mutation({
      query: (data) => ({
        url: `/customer/generate-ai-designs`,
        method: 'POST',
        body: data
      })
    }),
    getIcon: builder.mutation({
      query: ({ icon_uuid }) => ({
        url: `seller/get-design-icons?is_active=True&icon_id=${icon_uuid ?? ''}`,
        method: 'GET'
      })
    }),
    getProductList: builder.mutation({
      query: ({ sub_sub_category }) => ({
        url: `seller/get-product-list?is_active=True&sub_sub_category=${sub_sub_category ?? ''}`,
        method: 'GET'
      })
    }),
    getProductDetails: builder.mutation({
      query: ({ product_uuid }) => ({
        url: `seller/get-product-details?is_active=True&product_uuid=${product_uuid ?? ''}`,
        method: 'GET'
      })
    })
  })
});
