// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product_common_container .container {
  max-width: 1015px;
}
.product_common_container .product_prompt_inner {
  position: relative;
}
.product_common_container .product_prompt_inner input {
  height: 60px;
  background-color: #fff;
  padding: 10px 38px;
  border-radius: 12px;
  border: 1px solid #F1F1F1;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  padding-right: 78px;
  box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.0392156863);
}
.product_common_container .product_prompt_inner input :placeholder {
  color: #A7A7A7;
}
.product_common_container .product_prompt_inner .prompt_send_btn {
  padding: 0;
  position: absolute;
  right: 38px;
  top: 50%;
  transform: translateY(-50%);
  height: auto;
  display: flex;
  cursor: not-allowed;
}
.product_common_container .product_prompt_inner .active_prompt_send_but {
  cursor: pointer;
}
.product_common_container .stickers_wrap h3 {
  font-size: 17px;
  line-height: 1.2;
  color: #000;
  font-weight: 500;
  margin: 30px 0 50px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ProductPrompt/ProductPrompt.scss"],"names":[],"mappings":"AACI;EACI,iBAAA;AAAR;AAGI;EACI,kBAAA;AADR;AAEQ;EACI,YAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;EACA,wDAAA;AAAZ;AAEY;EACI,cAAA;AAAhB;AAGQ;EACI,UAAA;EACA,kBAAA;EACA,WAAA;EACA,QAAA;EACA,2BAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AADZ;AAGQ;EACI,eAAA;AADZ;AAMQ;EACI,eAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;AAJZ","sourcesContent":[".product_common_container{\n    .container{\n        max-width: 1015px;\n    }\n\n    .product_prompt_inner{\n        position: relative;\n        input{\n            height: 60px;\n            background-color: #fff;\n            padding: 10px 38px;\n            border-radius: 12px;\n            border: 1px solid #F1F1F1;\n            font-size: 18px;\n            font-weight: 500;\n            color: #000;\n            padding-right: 78px;\n            box-shadow: 0px 5px 13px 0px #0000000A;\n\n            :placeholder{\n                color: #A7A7A7;\n            }\n        }\n        .prompt_send_btn{\n            padding: 0;\n            position: absolute;\n            right: 38px;\n            top: 50%;\n            transform: translateY(-50%);\n            height: auto;\n            display: flex;\n            cursor: not-allowed;\n        }\n        .active_prompt_send_but{\n            cursor: pointer;\n        }\n    }\n\n    .stickers_wrap{\n        h3{\n            font-size: 17px;\n            line-height: 1.2;\n            color: #000;\n            font-weight: 500;\n            margin: 30px 0 50px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
