/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const wishListApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_URL}/api`,
        prepareHeaders: async (headers) => {
            // check user token
            if (process.env.REACT_APP_BACKEND_APP) {
                headers.set('Authorization', `${process.env.REACT_APP_BACKEND_APP}`);
            }
            return headers;
        },
    }),
    reducerPath: 'wishListApi',
    tagTypes: ['WishList'],
    endpoints: (builder) => ({
        getDesignIcons: builder.query({
            query: ({ customer_uuid, query }) => ({
                url: `/customer/get-wishlist-icons?customer_uuid=${customer_uuid}&${query}`,
                method: 'GET',
            }),
        })
    })
});
export const { useGetDesignIconsQuery } = wishListApi;
