// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkout_wrap .cart_left_inner_info_left {
  width: 100%;
}
.checkout_wrap .product_information {
  flex-grow: 1;
}
.checkout_wrap .cart_left_inner_info {
  padding-left: 25px;
  padding-right: 25px;
}

.order_summary_wrap {
  border: 1px solid #E9E4E4;
  border-radius: 15px;
}

.order_summary_title {
  padding: 15px 25px;
  border-bottom: 1px solid #E9E9E9;
}
.order_summary_title h3 {
  font-size: 17px;
  color: #000;
  font-weight: 500;
  line-height: 1.2;
}

.enter_coupon_wrap {
  display: flex;
  align-items: flex-end;
  gap: 12px;
  justify-content: space-between;
  padding: 15px 25px;
  border-top: 1px solid #E9E9E9;
  border-bottom: 1px solid #E9E9E9;
}
.enter_coupon_wrap .common_input {
  flex-grow: 1;
  margin-bottom: 0;
}
.enter_coupon_wrap button.apply_code_btn {
  max-width: 154px;
  height: 50px;
  width: 100%;
  background-color: #984CFA;
  border-radius: 10px;
}
.enter_coupon_wrap button.apply_code_btn:hover {
  background-color: #000 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Checkout/Checkout.scss"],"names":[],"mappings":"AACI;EACI,WAAA;AAAR;AAEI;EACI,YAAA;AAAR;AAGI;EACI,kBAAA;EACA,mBAAA;AADR;;AAKA;EACI,yBAAA;EACA,mBAAA;AAFJ;;AAIA;EACI,kBAAA;EACA,gCAAA;AADJ;AAEI;EACI,eAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;AAAR;;AAIA;EACI,aAAA;EACA,qBAAA;EACA,SAAA;EACA,8BAAA;EACA,kBAAA;EACA,6BAAA;EACA,gCAAA;AADJ;AAGI;EACI,YAAA;EACA,gBAAA;AADR;AAII;EACI,gBAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,mBAAA;AAFR;AAIQ;EACI,iCAAA;AAFZ","sourcesContent":[".checkout_wrap{\n    .cart_left_inner_info_left{\n        width: 100%;\n    }\n    .product_information{\n        flex-grow: 1;\n    }\n\n    .cart_left_inner_info{\n        padding-left: 25px;\n        padding-right: 25px;\n    }\n}\n\n.order_summary_wrap{\n    border: 1px solid #E9E4E4;\n    border-radius: 15px;\n}\n.order_summary_title{\n    padding: 15px 25px;\n    border-bottom: 1px solid #E9E9E9;\n    h3{\n        font-size: 17px;\n        color: #000;\n        font-weight: 500;\n        line-height: 1.2;\n    }\n}\n\n.enter_coupon_wrap{\n    display: flex;\n    align-items: flex-end;\n    gap: 12px;\n    justify-content: space-between;\n    padding: 15px 25px;\n    border-top: 1px solid #E9E9E9;\n    border-bottom: 1px solid #E9E9E9;\n\n    .common_input{\n        flex-grow: 1;\n        margin-bottom: 0;\n    }\n\n    button.apply_code_btn{\n        max-width: 154px;\n        height: 50px;\n        width: 100%;\n        background-color: #984CFA;\n        border-radius: 10px;\n\n        &:hover{\n            background-color: #000 !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
