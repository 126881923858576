/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const imageApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/api`,
    prepareHeaders: async (headers) => {
      // check user token
      if (process.env.REACT_APP_BACKEND_APP) {
        headers.set('Authorization', `${process.env.REACT_APP_BACKEND_APP}`);
      }
      return headers;
    }
  }),
  reducerPath: 'imageAPi',
  tagTypes: ['image'],
  endpoints: (builder) => ({
    postImageApi: builder.mutation({
      query: (data) => ({
        url: `/customer/get-ai-gen-image`,
        method: 'POST',
        body: data
      })
    })
  })
});
