/* eslint-disable no-unused-vars */
import plans from './data.json';
import { Button} from 'antd';
import { FaArrowRightLong } from 'react-icons/fa6';

import './PricingCard.scss';
import { useEffect, useState } from 'react';
import { useLayoutEffect } from 'react';
import { redirect } from 'react-router-dom';
const PricingCard = ({ annually, monthly }) => {
  const [country, setCountry] = useState('INR');
  const [exchangeRates, setExchangeRates] = useState(1);
 const calendlyLink = "https://calendly.com/nivera-ai/30min";
  const getCountry = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      const ct = data.currency;
      console.log(ct)
      //   console.log('PLAn', plans.exchangeRates[ct].rate);
      if (plans.exchangeRates[ct].rate) {
        setExchangeRates(plans.exchangeRates[ct].rate);
      } else {
        setExchangeRates(plans.exchangeRates['INR'].rate);
      }
    } catch (error) {
      console.log('ERROR', error);
    }
  };
  const redirectToCalendly = () => {
    console.log("here")
    window.open(calendlyLink, "_blank");
  };

  useEffect(() => {
    getCountry();
  });
  return (
    <div className="pricing-cards">
      {monthly &&
        plans.plans.map((plan) => (
          <div key={plan.id} className="pricing-card card">
            <div className="pricing-grp">
              <h2>{plan.title}</h2>
              <h2>
                {/* {plans.exchangeRates[country].symbol} */}
                {/* {(plan.price_monthly * exchangeRates).toFixed(2)} */}
                {(plan.price_monthly).toString()}
                <span className="package">/mo</span>
              </h2>
            </div>
            <button onClick={redirectToCalendly}>
              {plan.button_text}
              <FaArrowRightLong className="arrow" />
            </button>
            <ul>
              {Object.entries(plan.features).map(([feature, value]) => (
                <li key={feature}>
                  <strong>{feature}:</strong> {value}
                </li>
              ))}
            </ul>
          </div>
        ))}
      {annually &&
        plans.plans.map((plan) => (
          <div key={plan.id} className="pricing-card card">
            <div className="pricing-grp">
              <h2>Free</h2>
              <h2>
                {plans.exchangeRates[country].symbol}
                {(plan.price_annually * exchangeRates).toFixed(2)}
                <span className="package">(per year)</span>
              </h2>
            </div>
            <a href={calendlyLink} target="_blank" rel="noopener noreferrer" className="button" id="get-started">
              {plan.button_text}
              <FaArrowRightLong className="arrow" />
            </a>
            <ul>
              {Object.entries(plan.features).map(([feature, value]) => (
                <li key={feature}>
                  <strong>{feature}:</strong> {value}
                </li>
              ))}
            </ul>
          </div>
        ))}
    </div>
  );
};
export default PricingCard;
