/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const userApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/api`,
    prepareHeaders: async (headers) => {
    
        // check user token
        if (process.env.REACT_APP_BACKEND_APP) {
          headers.set('Authorization', `${process.env.REACT_APP_BACKEND_APP}`);
      }
      return headers;
    }
  }),
  reducerPath: 'userApi',
  tagTypes: ['User'],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (user) => ({
        url: '/customer/customer-login',
        method: 'POST',
        body: user
      })
    }),
    register: builder.mutation({
      query: (user) => ({
        url: '/customer/register-customer',
        method: 'POST',
        body: user
      })
    }),
    registerThirdParty: builder.mutation({
      query: (requestBody) => ({
        url: '/customer/register-customer-third-party',
        method: 'POST',
        body: requestBody
      })
    }),
    sendOtp: builder.mutation({
      query: (data) => ({
        url: '/customer/send-login-otp',
        method: 'POST',
        body: data
      })
    }),
    reSendOtp: builder.mutation({
      query: (data) => ({
        url: '/customer/resend-mobile-otp',
        method: 'POST',
        body: data
      })
    }),
    verifyOtp: builder.mutation({
      query: (data) => ({
        url: '/customer/verify-mobile-otp',
        method: 'POST',
        body: data
      })
    }),
    updateUserProfile: builder.mutation({
      query: ({ data, customer_uuid }) => ({
        url: `/customer/edit-customer-profile?cust_uuid=${customer_uuid}`,
        method: 'PUT',
        body: data
      })
    }),
    updateUserProfilePic: builder.mutation({
      query: ({ data, customer_uuid }) => ({
        url: `/customer/change-profile-picture?cust_uuid=${customer_uuid}`,
        method: 'PUT',
        body: data
      })
    }),
    addBillingAddress: builder.mutation({
      query: (data) => ({
        url: `/customer/add-customer-address`,
        method: 'POST',
        body: data
      })
    }),
    updateBillingAddress: builder.mutation({
      query: (data) => ({
        url: `/customer/update-customer-address`,
        method: 'PUT',
        body: data
      })
    }),
    getCountries: builder.query({
      query: () => ({
        url: `/master/get-countries`,
        method: 'GET'
      })
    }),
    getStates: builder.query({
      query: (country_id) => ({
        url: `/master/get-states?country_id=${country_id}`,
        method: 'GET'
      })
    }),
    getCites: builder.query({
      query: (state_id) => ({
        url: `/master/get-cities?state_id=${state_id}`,
        method: 'GET'
      })
    }),
    getAddress: builder.query({
      query: (customer_id) => ({
        url: `/customer/get-customer-addresses?cust_uuid=${customer_id}`,
        method: 'GET'
      })
    }),
    getCustomerProfile: builder.query({
      query: (customer_id) => ({
        url: `/customer/get-customer-profile?cust_uuid=${customer_id}`,
        method: 'GET'
      })
    }),
    getCountryCode: builder.query({
      query: () => ({
        url: 'master/get-countries-codes',
        method: 'GET'
      })
    }),
    bookFreeTrial: builder.query({
      query: (data) => ({
        url: 'customer/create-enquiry',
        method: 'POST',
        body: data
      })
    })
  })
});
export const { useLoginMutation, useRegisterMutation } = userApi;
