import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errorMsg: '',
  loading: false,
  isLoginLoading: false,
  stateLoading: false,
  cityLoading: false,
  btnLoading: false,
  success: false,
  user: {},
  isRegistrationSuccess: false,
  isOtpVerified: false,
  isNewUser: false,
  isActive: false,
  countries: [],
  states: [],
  cities: [],
  address: [],
  countryCode: [],
  bookFreeTrial:{},
};

const userSlice = createSlice({
  name: 'USER_SLICE',
  initialState,
  reducers: {
    setUserLoading: (state, action) => {
      state.loading = action.payload;
    },
    setBtnLoading: (state, action) => {
      state.btnLoading = action.payload;
    },
    setStateLoading: (state, action) => {
      state.stateLoading = action.payload;
    },
    setCityLoading: (state, action) => {
      state.cityLoading = action.payload;
    },
    setUserLoginLoading: (state, action) => {
      state.isLoginLoading = action.payload;
    },
    setUser: (state, action) => {
      const user = { ...state.user, ...action?.payload };
      state.user = user;
    },
    setError: (state, action) => {
      state.errorMsg = action.payload;
      state.success = false;
    },
    setIsRegistrationSuccess: (state, action) => {
      state.isRegistrationSuccess = action.payload;
      state.success = true;
    },
    setIsOtpVerified: (state, action) => {
      state.isOtpVerified = action.payload;
      state.success = true;
    },
    setLogin: (state, action) => {
      state.isActive = action.payload;
      state.success = true;
    },
    setAuth: (state, action) => {
      state.isNewUser = action.payload;
      state.success = true;
    },
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
    setStates: (state, action) => {
      state.states = action.payload;
    },
    setCities: (state, action) => {
      state.cities = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setCountryCode: (state, action) => {
      state.countryCode = action.payload;
    },
    setBookFreeTrial:(state,action)=>{
      state.bookFreeTrial=action.payload
    }
  }
});

export const {
  setUser,
  setUserLoading,
  setError,
  setIsRegistrationSuccess,
  setAuth,
  setCountries,
  setStates,
  setCities,
  setStateLoading,
  setCityLoading,
  setAddress,
  setBtnLoading,
  setIsOtpVerified,
  setLogin,
  setUserLoginLoading,
  setCountryCode,
  setBookFreeTrial
} = userSlice.actions;
export default userSlice.reducer;
