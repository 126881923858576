import { theme } from 'antd';

const THEME_CONFIGURATION = {
  algorithm: theme.compactAlgorithm,
  token: {
    // colorPrimary: '#f8991f',
    // colorBgBase: '#333333',
    fontFamily: 'Urbanist, sans-serif',
    colorError: '#cc0000',
    fontSize: 16,
    borderRadius: 4,
    sizeStep: 4,
    sizeUnit: 6,
    colorPrimaryText: 'red',
  },
  components: {
    Button: {
      colorPrimary: '#0c611a',
      colorPrimaryHover: '#1f6e29',
    },
    Radio: {
      colorPrimary: '#f8991f',
      colorPrimaryHover: '#ffb74a',
    },
    Checkbox: {
      colorPrimary: '#f8991f',
      colorPrimaryHover: '#ffb74a',
    },
    Input: {
      colorPrimaryHover: '#984CFA'
    },
    Select: {
      colorPrimaryHover: '#984CFA'
    }
  },
};

export default THEME_CONFIGURATION;
